import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch, SearchBox, Hits, Configure,
} from 'react-instantsearch-dom';
import { CustomHits } from './CustomHits';
import './index.css';

const ClickOutHandler = require('react-onclickout');

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
);

const searchClient = {
  search(requests) {
    const newRequests = requests.map((request) => {
      // test for empty string and change request parameter: analytics
      if (!request.params.query || request.params.query.length === 0) {
        request.params.analytics = false;
      }
      return request;
    });
    return algoliaClient.search(newRequests);
  },
};

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasInput: false,
      refresh: false,
    };
  }

  onClickOut = (e) => {
    if (e.target.className !== 'ais-SearchBox-input') {
      document.getElementsByClassName('ais-SearchBox-input')[0].value = '';
      this.setState(() => ({
        hasInput: false,
      }));
    }
  }

  render() {
    const { refresh, hasInput } = this.state;
    return (
      <InstantSearch
        searchClient={searchClient}
        indexName="Pages"
        refresh={refresh}
      >
        <Configure hitsPerPage={5} />

        {/* forcefeed className because component does not accept natively as prop */}
        <i className="col-lg-1 fas fa-search" />
        <SearchBox
          className="searchbox col-lg-8 ais-SearchBox-input"
          submit={<></>}
          reset={<></>}
          translations={{
            placeholder: 'Big Tiddy',
          }}
          onKeyUp={(event) => {
            this.setState({
              hasInput: event.currentTarget.value !== '',
            });
          }}
          onClick={(event) => {
            this.setState({
              hasInput: event.currentTarget.value !== '',
            });
          }}
        />

        <ClickOutHandler onClickOut={this.onClickOut} wrapWith="div">
          <div className={!hasInput ? 'input-empty' : 'input-value'}>
            <CustomHits hitComponent={Hits} />
          </div>
        </ClickOutHandler>
      </InstantSearch>
    );
  }
}

export default Search;
