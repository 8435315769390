import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import './index.css';

const Copyright = styled.p`
  text-align: center;
  margin: auto;
  font-size: 12px;
`;

const Footer = () => (
  <footer className="footer mt-5">
    <div className="container">
      <div className="row">
        <Link className="col" to="/rss.xml">
          RSS
          {' '}
          <i className="fas fa-rss" />
        </Link>
        <Link className="col" to="/privacy-policy">Privacy Policy</Link>
        <Link className="col" to="/terms-and-conditions">Terms and Conditions</Link>
        <a className="col" href="mailto:contact@horny.moe">Contact Us</a>
        <a className="col" href="https://forms.gle/YJsGkwjFTn4pcQWP7">Sponsorship Inquiries</a>
        <Copyright className="col">© 2022 hornydotmoe All rights reserved.</Copyright>
      </div>
    </div>
  </footer>
);

export default Footer;
