/*
  eslint
  react/prop-types: 0,
  no-underscore-dangle: 0
*/
import React from 'react';
import { Link } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import { connectSearchBox, connectHits } from 'react-instantsearch-dom';

import algolia from '../../images/algolia.webp';

const SearchBox = ({ currentRefinement, refine }) => (
  <div className="ais-SearchBox">
    <form noValidate action="" role="search" className="ais-SearchBox-form">
      <input
        className="ais-SearchBox-input"
        type="search"
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
      />
    </form>
  </div>
);

export const CustomSearchBox = connectSearchBox(SearchBox);

// on page load do not display
const Hits = ({ hits }) => (
  // if parent component set is type, render, otherwise hide
  <ul className="style">
    {hits.length < 1 ? <li>No search results found</li> : ''}
    {hits.map((hit) => (
      <li key={hit.title}>
        <Link to={hit.fields.slug}>
          <span className="search-title" dangerouslySetInnerHTML={{ __html: hit._highlightResult.title.value }} />
          {' '}
          |
          {' '}
          {hit._highlightResult.tags.map((tag, index) => (
            <span key={uuidv4()}>
              <span className="search-tag" dangerouslySetInnerHTML={{ __html: tag.value }} />
              {index === hit._highlightResult.tags.length - 1 ? '' : ', '}
            </span>
          ))}
          <p dangerouslySetInnerHTML={{ __html: hit._snippetResult.excerpt.value }} />
        </Link>
      </li>
    ))}
    <li className="text-right">
      <img
        className="ml-auto"
        style={{
          maxHeight: '20px',
        }}
        src={algolia}
        alt="algolia logo"
      />
    </li>
  </ul>
);

export const CustomHits = connectHits(Hits);
