import React from 'react';
import './index.css';

class AgeCheck extends React.Component {
  constructor() {
    super();
    this.state = {
      ageOk: true,
      verify: () => {
        this.setState({
          ageOk: true,
        });
        localStorage.ageOk = 'true';
      },
    };
  }

  componentDidMount() {
    if (localStorage.ageOk === 'true') {
      this.setState({
        ageOk: true,
      });
    } else {
      this.setState({
        ageOk: false,
      });
    }
  }

  render() {
    const { ageOk, verify } = this.state;
    if (ageOk) {
      return (
        <></>
      );
    }
    return (
      <div>
        <div className="ageCheckWindow container" />
        <div className="row ageCheckRow">
          <div className="col-12 col-md-4 offset-md-4 text-center ageCheckBox">
            <h3 className="ageCheckTitle">You must be at least 18 years old to continue</h3>
            <button className="ageCheckButton align-middle" onClick={verify} type="button">I&apos;m 18 or older</button>
          </div>
        </div>
      </div>
    );
  }
}

export default AgeCheck;
