import { Link } from 'gatsby';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import './index.css';
import logo from '../../images/logo.png';
import mascot from '../../images/hornymascot.png';
import Search from '../Search';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteTitle: props.siteTitle,
      currentPath: '/',
      streamLive: false,
    };
  }

  async componentDidMount() {
    let streamLive = false;
    try {
      const response = await axios.get('https://live.horny.moe/status');
      streamLive = response.status === 200;
    } catch (err) {
      streamLive = false;
    }
    this.setState({
      currentPath: window.location.pathname,
      streamLive,
    });
  }

  isArticle() {
    const { currentPath } = this.state;
    return (currentPath.includes('/articles/') || currentPath.includes('/reviews/') || currentPath.includes('/editorials/'));
  }

  render() {
    const { siteTitle, currentPath, streamLive } = this.state;
    return (
      <>
        <nav className="header navbar navbar-expand-lg mb-5">
          <Link className="navbar-brand" to="/">
            <img className="header-logo" src={mascot} alt="horny.moe's mascot" />
            <img className="header-logo" src={logo} alt={siteTitle} />
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">

              <li className={`nav-item dropdown${this.isArticle() ? ' active' : ''}`}>
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Articles
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className={`dropdown-item${currentPath.includes('/articles/') ? ' active' : ''}`} to="/articles/">All</Link>
                  <Link className={`dropdown-item${currentPath.includes('/reviews/') ? ' active' : ''}`} to="/reviews/">Reviews</Link>
                  <Link className={`dropdown-item${currentPath.includes('/editorials/') ? ' active' : ''}`} to="/editorials/">Editorials</Link>
                </div>
              </li>
              <li className={`nav-item${currentPath.includes('/stream/') ? ' active' : ''}`}>
                <Link className="nav-link streamLink" to="/stream/">
                  Stream
                  {' '}
                  {
                    streamLive
                      ? <i className="d-inline fas fa-video streamLiveIcon" />
                      : <></>
                  }
                </Link>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="https://patreon.com/hornydotmoe">Patreon</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://teespring.com/stores/hornydotmoe">Merch</a>
              </li>

            </ul>
            <div className="form-inline">
              <Search />
            </div>
          </div>
        </nav>
        <span
          className="ml-5"
          style={{
            fontSize: '30px',
            position: 'absolute',
            top: '90px',
            right: '60px',
          }}
        >
          <a
            href="https://www.weareplannedparenthood.org/onlineactions/2U7UN1iNhESWUfDs4gDPNg2"
            target="_blank"
            rel="noopener noreferrer"
          >
            &#9878;&#65039;
          </a>
        </span>
      </>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
